import '../sass/client.scss';

import alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import persist from '@alpinejs/persist';
import intersect from '@alpinejs/intersect';
import cursor from 'alpinejs-cursor';
import 'fslightbox';
import 'share-buttons';

import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { searchBox, hits } from 'instantsearch.js/es/widgets';

alpine.plugin(collapse);
alpine.plugin(persist);
alpine.plugin(intersect);
alpine.plugin(cursor);

window.Alpine = alpine;

window.addEventListener('load', () => {
  alpine.start();
  refreshFsLightbox();

  const searchClient = algoliasearch(
    '7Z9YFH4J9U',
    'adcb60cf7e08a926e52fe97a27d759a6',
  );

  const search = instantsearch({
    indexName: 'huduu',
    searchClient,
  });

  search.addWidgets([
    searchBox({
      container: '#searchbox',
    }),

    hits({
      container: '#hits',
      templates: {
        item(hit, { html, components }) {
          return html`
            <div class="mb-4 flex w-full flex-col">
              <a
                class="mb-1 w-full text-lg font-semibold text-gray-700"
                href="${hit.url}"
              >
                ${components.Highlight({ attribute: 'title', hit })}
              </a>
              <p class="m-0 mb-2 text-gray-700">${hit['excerpt']}</p>
              <span class="text-sm text-gray-500">${hit.date}</span>
              <a
                class="w-max text-blue-400 visited:text-purple-400"
                href="${hit.url}"
              >
                Дэлгэрэнгүй
              </a>
            </div>
          `;
        },
      },
    }),
  ]);

  search.start();
});
